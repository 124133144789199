import image1 from "../images/service-single/1.jpg";
import image2 from "../images/service-single/2.jpg";
import image3 from "../images/service-single/3.jpg";

const icon1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689907725/HFAF/cancer_fkgfma.svg";

const icon2 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689932484/HFAF/arthritis_bl2amf.svg";

const icon3 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689914229/HFAF/water_x60fvq.svg";

const Services = [
  {
    id: "1",
    fIcon1: icon1,
    title: "Cancer Cure",
    description:
      "At the Health for All Foundation, we are committed to supporting the most vulnerable among us who are battling cancer. We strive to eliminate financial barriers to cancer treatment, working tirelessly to raise funds that directly impact patients' lives. Our aim is to ensure that everyone, regardless of their economic status, has access to life-saving cancer therapies and can face the disease with dignity and hope.",
    simg1: image1,
    simg2: image2,
    simg3: image3,
  },
  {
    id: "2",
    fIcon1: icon2,
    title: "Arthritis Cure",
    description:
      "Arthritis can be a debilitating disease, limiting the physical capabilities and quality of life for those it affects. We believe that everyone deserves the right to move freely and live without pain. By funding advanced research and treatments, we aim to bring relief to those suffering from arthritis. Our goal is to create a world where arthritis is no longer an obstacle to living a fulfilling life.",
    simg1: image1,
    simg2: image2,
    simg3: image3,
  },
  {
    id: "3",
    fIcon1: icon3,
    title: "Clean Water",
    description:
      "Water is the essence of life. Yet, millions globally lack access to clean, safe drinking water. We seek to change this reality by funding projects aimed at providing sustainable, clean water solutions to impoverished communities. By enabling access to this basic human necessity, we are not just providing water; we are giving individuals the opportunity for healthier, more productive lives.",
    simg1: image1,
    simg2: image2,
    simg3: image3,
  },
];
export default Services;
