import React, { Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import blogs from "../../api/blogs";
import BlogSidebar from "../../components/BlogSidebar";
import Blog1 from "../../api/Blogs/Blog1";
import Blog2 from "../../api/Blogs/Blog2";
import Blog3 from "../../api/Blogs/Blog3";

const blogComponents = {
  1: Blog1,
  2: Blog2,
  3: Blog3,
};

const BlogSingle = (props) => {
  const { id } = useParams();
  const BlogDetails = blogs.find((item) => String(item.id) === id);

  const BlogPost = blogComponents[id];

  if (!BlogPost || !BlogDetails) return null;

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <Suspense fallback={<div>Loading...</div>}>
                <BlogPost />
              </Suspense>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Tags: </span>
                  <ul>
                    {BlogDetails && BlogDetails.tags
                      ? BlogDetails.tags.map((tag, index) => (
                          <li key={index}>
                            <Link to={`/tag/${tag}`}>{tag}</Link>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className="tag-share-s2 clearfix">
                <div className="tag">
                  <span>Share: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">facebook</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">twitter</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">linkedin</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">pinterest</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
