import eimg1 from "../images/event/img-1.jpg";
import eimg2 from "../images/event/img-2.jpg";
import eimg3 from "../images/event/img-3.jpg";
import eimg4 from "../images/event/img-4.jpg";

const imageArt =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689787120/HFAF/Art-health_dkmpw6.jpg";

const imageMusic =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689847038/HFAF/music-band_nf7ufr.png";

const imageTalk =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689848150/HFAF/qmrt-speaker_gzs3pi.png";
const Events = [
  {
    id: "1",
    eImg: imageTalk,
    date: "5 Sep, 2023",
    eTitle: "QMRT Fundraiser",
    dec: "Join us for an informative session on QMRT and its benefits for treating cancer and osteoarthritis. Your donations can bring healing to many.",
    longDec:
      "Join us for a powerful session unveiling the hope Quantum Magnetic Resonance Therapy (QMRT) offers for those battling cancer and arthritis. Through the inspiring stories of survivors, you'll witness the life-altering potential of this treatment. This is not just about us sharing; it's an opportunity for you to become a beacon of hope for underprivileged individuals who can't afford this life-saving intervention. Your contribution has the power to bring health and happiness back to many lives. Come, be a part of this healing journey.",
    schedule:
      "<ul><li>Registration and Refreshments</li><li>Introduction to Quantum Magnetic Resonance Therapy (QMRT)</li><li>Interactive QMRT Demonstration: Experience the benefits first-hand</li><li>Guest Speaker Session: Survivor's Story</li><li>Fundraising Initiative: Pledge your support and make a difference</li><li>Networking and Social Hour</li></ul>",
  },
  {
    id: "2",
    eImg: imageArt,
    date: "7 Oct, 2023",
    eTitle: "Art for Health Auction",
    dec: "Bid on beautiful artwork from local artists. All proceeds will support providing QMRT treatments to the underprivileged.",
    longDec:
      "Experience an evening enveloped in the beauty of art, as we auction stunning creations by local artists. Each artwork tells a poignant tale of resilience and hope, symbolizing our fight against disease. The joy of owning a unique piece of art becomes even more fulfilling when the proceeds are directed towards providing the underprivileged access to advanced QMRT treatments for cancer and arthritis. It's more than just an auction, it's a chance for you to make a lasting impact on someone's life journey. Join us and be a part of this transformation.",
    schedule:
      "<ul><li>Welcome and Art Exhibition: Explore stunning artwork from local talents</li><li>Auction Kick-off: Grab the opportunity to own unique pieces of art</li><li>Interlude Performance: Special musical presentation to enhance your evening</li><li>Auction Resumes: More remarkable artwork up for bid</li><li>Announcement of Highest Bids: Celebrate your successful bids and contributions</li><li>Evening Closure: End the evening with a sense of accomplishment</li></ul>",
  },
  {
    id: "3",
    eImg: imageMusic,
    date: "12 Nov, 2023",
    eTitle: "Music for Healing Concert",
    dec: "Enjoy a night of music from local bands and artists. Ticket proceeds will fund our health initiatives.",
    longDec:
      "Surrender to the rhythm of music at our healing concert, where every note played resonates with our commitment to fight disease. This is not merely a concert, but a symphony of hope, as we stand together to provide the underprivileged access to cutting-edge QMRT technology for cancer and arthritis treatment. Your ticket fuels this endeavor, transforming lives one melody at a time. Witness the power of unity and the magic of music in bringing about change. Let's come together, tune into empathy, and turn the tide in the battle against illness.",
    schedule:
      "<ul><li>Doors Open: Get ready for an unforgettable night</li><li>Opening Act: Talented local band warms up the stage</li><li>Intermission: Grab a bite, stretch your legs, and mingle with fellow concert-goers</li><li>Main Performances: A medley of music genres from an exciting lineup of artists</li><li>Finale Performance: A special act to remember, dedicated to healing and hope</li><li>After-Party: Continue the festivities and dance the night away</li></ul>",
  },
  {
    id: "4",
    eImg: eimg4,
    date: "10 Dec, 2023",
    eTitle: "Safe Drinking Water Awareness and Fundraiser",
    dec: "Learn more about the importance of safe drinking water and our initiatives in this area. Your donations can help us provide safe water to more communities.",
    longDec:
      "Water is life's essential, yet many struggle for access to clean, safe drinking water. Join us in an enlightening event about this global challenge and our initiatives to counter it. Your participation and contribution can catalyze our efforts in securing safe water for underprivileged communities and supporting their health. Simultaneously, funds raised will also aid in providing life-changing QMRT treatments to those combating cancer and arthritis. Your support could bring a cascade of change, quenching thirsts and healing lives. Come, make waves with us in this fight for safe water and better health.",
    schedule:
      "<ul><li>Introduction and Documentary Screening: The struggle for safe water</li><li>Interactive Display: What does safe drinking water mean to you?</li><li>Panel Discussion: Experts discuss solutions for water safety</li><li>Fundraiser Launch: Be a part of the solution, every drop counts</li><li>Water-Themed Art Installation: Artistic interpretation of the day's reflections</li><li>Networking and Refreshments: Connect over a cup of the world's most precious resource - water</li></ul>",
  },
];

export default Events;
