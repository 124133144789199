const BlogImg1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689851606/HFAF/cancer_eu5dmu.png";
const BlogImg2 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689851606/HFAF/arthritis_qtcg5d.png";

const BlogImg3 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1690348406/HFAF/qmrt_psnr3h.png";

const blogs = [
  {
    id: "1",
    title:
      "Winning the War on Cancer: Unleashing the Power of Diet as Your Main Weapon",
    screens: BlogImg1,
    description:
      "Discover the power of food as a vital weapon in your battle against cancer. This blog unfolds how a well-regulated diet can bolster your immune system, inhibit cancer growth, and ultimately aid in winning the war on cancer.",
    create_at: "03 JUL,23",
    blClass: "format-standard-image",
    thumb: "Cancer",
  },
  {
    id: "2",
    title:
      "Unravelling the Arthritis Diet: Your Comprehensive Guide to Foods that Aid and Ail",
    screens: BlogImg2,
    description:
      "Decoding the connection between arthritis and diet, this guide provides a comprehensive understanding of foods that can alleviate arthritis symptoms and those which can exacerbate them. Arm yourself with knowledge to better manage arthritis.",
    create_at: "07 JUL,23",
    blClass: "format-standard-image",
    thumb: "Arthritis",
  },

  {
    id: "3",
    title: "Understanding Quantum Magnetic Resonance Therapy (QMRT)",
    screens: BlogImg3,
    description:
      "As the world grapples with various health challenges, technological advancements have opened new horizons for disease management and treatment. One such promising intervention is Quantum Magnetic Resonance Therapy (QMRT).",
    create_at: "20 JUL,23",
    blClass: "format-standard-image",
    thumb: "QMRT",
  },
];

export default blogs;
