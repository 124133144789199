import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo2.png";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p>
                  The Health For All Foundation is dedicated to ensuring quality
                  healthcare and safe drinking water for the underprivileged.
                  Join us in our mission to improve well-being for all.
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/healthforall.foundation.india"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hfaf.india/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/health-for-all-foundation/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Causes </h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/cancer-treatment">
                      Cancer Treatment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/arthritis-treatment">
                      Arthritis Treatment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/safe-drinking-water">
                      Safe Drinking Water
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <p>We're here for any inquiries. Feel free to reach out:</p>
                  <ul>
                    <li>
                      <i className="fi flaticon-mail"></i>contact@myhfaf.org
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>(+91) 88841
                      16996
                    </li>
                    <li>
                      <i className="fi flaticon-location"></i>G2, Memphis,
                      Langford Road, Richmond Town, Bengaluru 560025
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                &copy; {currentYear} Health for All Foundation. Developed and
                Maintained by{" "}
                <a
                  href="http://www.wisdomworks.co"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Wisdom Works
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
