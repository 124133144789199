import React from "react";
import blogs from "../../api/blogs";
import { Link } from "react-router-dom";

const BlogSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-blog-section section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <span>Our Blog</span>
              <h2>Explore Health Insights & Updates</h2>
              <p>
                Stay informed about the latest developments in health, learn
                about new research, and gain insights into the work we do at the
                Health for All Foundation.
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-blog-items">
          <div className="row">
            {blogs.slice(0, 3).map((blog, Bitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={Bitem}>
                <div className="wpo-blog-item">
                  <div className="wpo-blog-img">
                    <img src={blog.screens} alt="" />
                  </div>
                  <div className="wpo-blog-content">
                    <div className="wpo-blog-content-top">
                      <h2>
                        <Link
                          onClick={ClickHandler}
                          to={`/blog-single/${blog.id}`}
                        >
                          {blog.title}
                        </Link>
                      </h2>
                      <p> {blog.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
