import React from "react";
import { Link } from "react-router-dom";
import abimg from "../../images/about.jpg";
import shape from "../../images/ab-shape-2.png";

// Here are the Cloudinary image links. Replace these URLs with your own if needed.
const about1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348626/HFAF/woman-baby_oqtsnz.png";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={about1} alt="" />
                <div className="wpo-ab-shape-1">
                  <div className="s-s1"></div>
                  <div className="s-s2"></div>
                </div>
                <div className="wpo-ab-shape-2">
                  <img src={shape} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <span>About Us</span>
              <h2>Caring for the Underserved. Because Every Life Matters.</h2>
              <p>
                The Health For All Foundation believes in the fundamental right
                to health for everyone, regardless of financial means. We raise
                funds to support the underprivileged in their battles against
                cancer and arthritis, and to ensure access to clean water - a
                basic necessity often denied.
              </p>
              <ul>
                <li>
                  We champion the potential of Quantum Magnetic Resonance
                  Therapy (QMRT) in cancer treatment, making it accessible to
                  those in need.
                </li>
                <li>
                  We support breakthroughs in arthritis treatment, bringing hope
                  and relief to sufferers.
                </li>
                <li>
                  We work tirelessly to provide clean water access in
                  marginalized communities, because good health begins with
                  clean water.
                </li>
              </ul>
              <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">
                More About
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
