import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../api/blogs";
import Services from "../../api/service";
import Projects from "../../api/projects";
import about from "../../images/blog/about-widget.jpg";

const BlogSidebar = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`col col-lg-4 col-md-12 col-12 ${props.blLeft}`}>
      <div className="blog-sidebar">
        <div className="widget search-widget">
          <form onSubmit={SubmitHandler}>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Search Post.."
              />
              <button type="submit">
                <i className="ti-search"></i>
              </button>
            </div>
          </form>
        </div>
        <div className="widget category-widget">
          <h3>Categories</h3>
          <ul>
            {Services.slice(0, 6).map((service, Sitem) => (
              <li key={Sitem}>
                <Link
                  onClick={ClickHandler}
                  to={`/service-single/${service.id}`}
                >
                  {service.title} <span>{service.id}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="widget recent-post-widget">
          <h3>Related Posts</h3>
          <div className="posts">
            {blogs.slice(0, 3).map((blog, Bitem) => (
              <div className="post" key={Bitem}>
                <div className="img-holder">
                  <img src={blog.screens} alt="" />
                </div>
                <div className="details">
                  <h4>
                    <Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>
                      {blog.title}
                    </Link>
                  </h4>
                  <span className="date">{blog.create_at}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="widget wpo-instagram-widget"></div>
        <div className="widget tag-widget">
          <h3>Tags</h3>
          <ul>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Charity
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Planning
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Helping
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Education
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Medical
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Wildlife
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Donation
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                Help
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/cause-single/1">
                World Pandamic
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
