import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

const EventTabs = ({ event }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="wpo-event-details-wrap">
      <div className="wpo-event-details-tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Event Schedule
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Map Location
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Contact Us
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <div className="wpo-event-details-content">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <div id="Schedule" className="tab-pane active">
                  <p>Date: {event.date}</p>
                  <div dangerouslySetInnerHTML={{ __html: event.schedule }} />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <div id="Map" className="tab-pane">
                  <div className="contact-map">
                    {/* Update this iframe to change location based on your event */}
                    <iframe src="https://maps.google.com"></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <div className="event-contact">
                  <div className="wpo-donations-details">
                    <form
                      method="post"
                      className="contact-validation-active"
                      id="contact-form-main"
                      onSubmit={SubmitHandler}
                    >
                      <div className="row">{/* Contact Form */}</div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default EventTabs;
