import React from "react";

const HeaderTopbar = () => {
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-7 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <i className="fi ti-location-pin"></i>G2, Memphis, Langford
                  Road, Bengaluru
                </li>
                <li>
                  <i className="fi flaticon-email"></i>info@myhfaf.org
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-md-5 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                <li>Visit our social pages</li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/healthforall.foundation.india"
                  >
                    <i className="ti-facebook"></i>
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/hfaf.india/"
                  >
                    <i className="ti-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/health-for-all-foundation/"
                  >
                    <i className="ti-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
