import React from "react";
import { Link } from "react-router-dom";

const abimg =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1689933117/HFAF/poor-man_d0i0dg.png";

const AboutS2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section-s2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />

                <div className="round-ball-1"></div>
                <div className="round-ball-2"></div>
                <div className="round-ball-3"></div>
                <div className="round-ball-4"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <span>About Us</span>
              <h2>
                Health For All Foundation - Bringing Hope, Health and Happiness
                to All.
              </h2>
              <p>
                The Health For All Foundation believes that everyone, regardless
                of their financial standing, should have access to quality
                healthcare, basic facilities, and clean water. We focus
                primarily on ensuring the less privileged sections of our
                society have both access to and funds for healthcare, nurturing
                their mental, emotional, and physical well-being.
              </p>
              <ul>
                <li>
                  Championing Quantum Magnetic Resonance Therapy (QMRT) in the
                  battle against cancer and arthritis.
                </li>
                <li>
                  Dedicated to providing safe drinking water to marginalized
                  communities.
                </li>
                <li>
                  Promoting health awareness and hygiene - because an informed
                  society is a healthier society.
                </li>
              </ul>
              <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutS2;
