import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";

const Service = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const iconStyle = {
    display: "flex", // Display the div as a flex container
    justifyContent: "center", // Center items horizontally
    alignItems: "center", // Center items vertically
    width: "150px", // Set the size of the parent div, adjust this to desired size
    height: "150px", // Set the size of the parent div, adjust this to desired size
    borderRadius: "50%", // To make it circular
    background: "#ffffff", // If this is the color of your circle
    position: "relative",
    top: "-75px", // Adjust this to suit the positioning of the circle
  };

  const imgStyle = {
    width: "80px", // Adjust the width as required
    height: "80px", // Adjust the height as required
  };

  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}
    >
      <div className="container">
        <div className="row">
          {Services.slice(0, 3).map((service, sitem) => (
            <div className="col col-xl-4 col-lg-6 col-sm-6 col-12" key={sitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon" style={iconStyle}>
                  <img
                    src={service.fIcon1}
                    alt="Service Icon"
                    style={imgStyle}
                  />{" "}
                </div>

                <div className="wpo-features-text">
                  <h2>
                    <Link
                      onClick={ClickHandler}
                      to={`/service-single/${service.id}`}
                    >
                      {service.title}
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
