import React from "react";
import { Link } from "react-router-dom";

// Here are the Cloudinary image links. Replace these URLs with your own if needed.
const hero1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698347984/HFAF/boy_ymjytj.webp";

const hero3 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698347984/HFAF/girl_ilquns.webp";
const Hero = () => {
  return (
    <section className="wpo-hero-section-1">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-xs-6 col-lg-6">
            <div className="wpo-hero-section-text">
              <div className="wpo-hero-title-top">
                <span>Transforming Lives, One Donation at a Time</span>
              </div>
              <div className="wpo-hero-title">
                <h2>Your Generosity Can Rewrite the Story of a Life</h2>
              </div>
              <div className="wpo-hero-subtitle">
                <p>
                  Through your support, we empower the underserved by funding
                  critical healthcare treatments and providing clean water. Join
                  us to make a difference.
                </p>
              </div>
              <div className="btns">
                <Link
                  to="https://pages.razorpay.com/pl_F3GHfjywv8EX8k/view"
                  className="btn theme-btn-s2"
                >
                  Donate Now
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-vec">
              <div className="right-items-wrap">
                <div className="right-item">
                  <div className="r-img">
                    <img src={hero1} alt="" />
                  </div>
                </div>
                <div className="right-item">
                  <div className="wpo-total-project">
                    <div className="wpo-total-project-wrap">
                      <div className="wpo-total-project-icon">
                        <i className="fi flaticon-salary"></i>
                      </div>
                      <div className="wpo-total-project-text">
                        <h3>252+</h3>
                        <p>Total Projects</p>
                      </div>
                    </div>
                  </div>
                  <div className="r-img">
                    <img src={hero3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
