const timg1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698349138/HFAF/cancer-woman_kwrjtq.png";

const timg2 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348936/HFAF/arthritis-2_q90hdk.png";

const timg3 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348774/HFAF/girl-water_uz6l4v.png";

const cimg1 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348406/HFAF/Cancer_ag7db9.png";

const cimg2 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348311/HFAF/arthritis_gmzcrv.png";

const cimg3 =
  "https://res.cloudinary.com/dxdpahm3o/image/upload/v1698348474/HFAF/Drinking-water_piva2y.png";

const Causes = [
  {
    id: "1",
    cTitle: "Cancer Cure with QMRT",
    cText:
      "At the Health For All Foundation, we are driven by a single goal: to provide quality healthcare to all, regardless of their financial situation. We have identified a powerful weapon against cancer—Quantum Magnetic Resonance Therapy (QMRT). This innovative 28-day non-invasive cancer treatment has proven to be effective in stopping the growth of cancer tumors during the early stages of the disease. It offers a lifeline to patients, giving them a chance at a longer and healthier life. However, we realize that many treatments are out of reach for individuals because of their high costs. We are committed to making QMRT accessible and affordable for all, especially those from poorer sections of society who are suffering from cancer. We aim to raise funds for this purpose, while also spreading awareness about the benefits and potentials of QMRT. Our goal is to not only treat the disease but also promote holistic healing, taking into account emotional, mental, and physical health. Your support can help us make a difference.",
    cImg: cimg1,
    tImg: timg1,
  },
  {
    id: "2",
    cTitle: "Arthritis Cure with QMRT",
    cText:
      "The Health For All Foundation is unwavering in its commitment to providing effective treatments for the underprivileged members of society. We believe that every individual deserves a life free from the debilitating effects of arthritis. Our primary treatment approach leverages the power of Quantum Magnetic Resonance Therapy (QMRT), a non-invasive and relatively pain-free method. This 21-day treatment plan has demonstrated successful management of arthritis symptoms, leading to improved quality of life and increased longevity for our patients. Yet, despite the proven success of QMRT, many people lack access to this potentially life-altering treatment due to its cost. This is why we are dedicated to making this treatment widely available to those in need, irrespective of their economic condition. We also conduct awareness campaigns and educational programs to inform the public about arthritis, its prevention, and treatments.",
    cImg: cimg2,
    tImg: timg2,
  },
  {
    id: "3",
    cTitle: "Safe Drinking Water for All",
    cText:
      "At the Health For All Foundation, we hold that access to safe drinking water is a fundamental human right. Clean water is not a luxury, but a cornerstone of public health. Despite advances in technology and infrastructure, many communities, particularly those in remote areas, continue to lack access to this basic necessity. We have partnered with international and local companies with groundbreaking technology capable of providing safe drinking water with minimal environmental impact and water wastage. Our goal is to convert the dream of safe drinking water for every individual into a reality, especially for marginalized communities. The funds we raise are used to procure more units, enabling us to reach even the most interior parts of the country. Join us in our mission to ensure safe drinking water for all, and together, we can make a difference.",
    cImg: cimg3,
    tImg: timg3,
  },
];

export default Causes;
