import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Causes from "../../api/cause";
import { useParams } from "react-router-dom";

const CauseTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();

  const CauseDetails = Causes.find((item) => item.id === id);

  return (
    <div className="wpo-case-details-text">
      <Row>
        <Col sm="12">
          <div className="wpo-case-content">
            <div className="wpo-campaign-img"></div>
            <h2>{CauseDetails.cTitle}</h2>
            <div className="case-b-text">
              <p>{CauseDetails.cText}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CauseTabs;
