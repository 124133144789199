import React from "react";
import { Link } from "react-router-dom";
import Causes from "../../api/cause";

const CauseSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`wpo-campaign-area section-padding ${props.CmClass}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <span>Empowering Lives</span>
              <h2>Our Ongoing Initiatives</h2>
              <p>
                Dedicated to creating a world where health and wellness are not
                a privilege, but a right. Join us as we change lives, one
                campaign at a time.
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-campaign-wrap">
          <div className="row">
            {Causes.slice(0, 3).map((Cause, citem) => (
              <div className="col-lg-4 col-md-6 col-12" key={citem}>
                <div className="wpo-campaign-single">
                  <div className="wpo-campaign-item">
                    <div className="wpo-campaign-img">
                      <img src={Cause.cImg} alt={Cause.cTitle} />
                    </div>
                    <div className="wpo-campaign-content">
                      <div className="wpo-campaign-text-top">
                        <h2>
                          <Link
                            onClick={ClickHandler}
                            to={`/cause-single/${Cause.id}`}
                          >
                            {Cause.cTitle}
                          </Link>
                        </h2>
                        <div className="campaign-btn"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseSection;
